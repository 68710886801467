/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Button, Modal, Form } from 'react-bootstrap';
import { FaFileExcel } from 'react-icons/fa';
import { toast } from 'react-toastify';
import _orders from '../../services/order.service';

function ExportToExcelButton({ data, dataName, button, overlayPlacement = 'right', dataFetcherFunction }) {
	const [show, setShow] = useState(false);
	const [exportName, setExportName] = useState('');
	const [selectedFields, setSelectedFields] = useState([]);

	const columnMap = {
		categories: {
			name: { label: 'Nombre', link: (item) => `Category/${item._id}` },
		},
		users: {
			name: { label: 'Nombre', link: false },
			email: { label: 'E-mail', link: false },
			role: { label: 'Rol', link: false },
		},
		orders: { 
			internalId: { label: 'ID', link: (item) => `/order/${item._id}` },
			'shippingAddress.address': { label: 'Dirección', link: false },
			'shippingAddress.telephone': { label: 'Teléfono', link: false },
			'shippingAddress.timeForDelivery': {
				label: 'Horario de Entrega',
				link: false,
			},
			paymentMethod: { label: 'Método de pago', link: false },
			status: { label: 'Estado', link: false },
			seller: { label: 'Vendedor', link: false },
			orderItems: { label: 'Detalles', link: false }
		},
		products: {
			name: { label: 'Nombre' },
			brand: { label: 'Marca', link: false },
			price: { label: 'Precio', link: false, isCurrency: true },
			'category.name': { label: 'Categoría', link: false },
			countInStock: { label: 'Stock', link: false },
			active: { label: 'Está Activo?', link: false },
		},
	};

	const columns = Object.keys(columnMap[dataName] || {});
	const columnsPerRow = 2;

	const rows = Array.from(
		{ length: Math.ceil(columns.length / columnsPerRow) },
		(_, rowIndex) =>
			columns.slice(rowIndex * columnsPerRow, (rowIndex + 1) * columnsPerRow),
	);

	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const handleDownloadExcel = async () => {
		try {
			const res = await _orders.getExcelSheet(selectedFields, exportName);
			const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', exportName);
			document.body.appendChild(link);
			link.click();
		} catch (ex) {
			console.error(ex);
			toast.error('No se pudo exportar la lista,');
		}

	};


	return (
		<Row className="m-1">
			<Col className="d-flex">
				<OverlayTrigger
					placement={overlayPlacement}
					overlay={<Tooltip id="tooltip-top">Exportar a Excel</Tooltip>}
				>
					{button ? (
						React.cloneElement(button, { onClick: handleShow })
					) : (
						<Button size={'sm'} className="mb-1" style={{ margin: '0' }} onClick={handleShow}>
							<FaFileExcel />
						</Button>
					)}

				</OverlayTrigger>
			</Col>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Exportar datos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Group as={Row} className="mb-3 align-items-center">
						<Form.Label column md={6}>
							Nombre del archivo:
						</Form.Label>
						<Col>
							<Form.Control
								type="text"
								placeholder="Ingrese el nombre"
								value={exportName}
								onChange={(e) => setExportName(e.target.value)}
							/>
						</Col>
					</Form.Group>
					<>
						<p>Marque los campos que desea incluir</p>
						{rows.map((row, rowIndex) => (
							<Row key={rowIndex}>
								{row.map((column) => (
									<Col key={column} md={6}>
										<Form.Group
											as={Row}
											className="mb-3 align-items-center"
											controlId={`fieldsToInclude_${column}`}
										>
											<Form.Check
												className="col-8"
												type="checkbox"
												value={column}
												label={columnMap[dataName][column].label}
												defaultChecked={column === 'customId'}
												disabled={column === 'customId'}
												onChange={(e) => {
													const selected = e.target.checked;
													setSelectedFields((prevSelectedFields) =>
														selected
															? [...prevSelectedFields, column]
															: prevSelectedFields.filter((field) => field !== column),
													);
												}}
											/>
										</Form.Group>
									</Col>
								))}
							</Row>
						))}
					</>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancelar
					</Button>
					<Button variant="primary" onClick={handleDownloadExcel}>
						Exportar XLS
					</Button>
				</Modal.Footer>
			</Modal>
		</Row>
	);
}

export default ExportToExcelButton;
