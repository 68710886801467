import React, { useEffect, useRef, useState } from 'react'
import "./newProductImage.css"
import { TbCameraPlus } from "react-icons/tb";
import Card from 'react-bootstrap/Card'
import classnames from 'classnames';
import Cropper from 'react-easy-crop'
import { FaCheck, FaTimes } from "react-icons/fa"
import ImageCropper from '../../ImageCropper/ImageCropper';
import Col from 'react-bootstrap/esm/Col';
import { toast } from 'react-toastify';
import ButtonSecondaryTranslucidHover from '../../ButtonSecondaryTranslucid/ButtonSecondaryTranslucidHover';
import { Button } from 'react-bootstrap';
import _product from "../../../services/product.service";
import ImageCropModal from '../../ImageCropModal/ImageCropModal';

export default function NewProductImage({ product, disabled, token, onSuccess, nextStep, onChange, goBack, isStep}) {
    const [imgSrc, setImgSrc] = useState(null);
    const inputFileRef = useRef(null);

    const saveImage = async (image) => {
        const file = image.blob;
        const formData = new FormData();
        let r = Math.random().toString(36).substring(7);
        formData.append("file", file, product.name.replaceAll(" ") + r);
        try {
            const response = await _product.uploadProductImage(formData);
            onSuccess(response.data);
            setImgSrc(null);
        } catch (err) {
            console.error(err);
            toast.error("Error cargando imagen.");
        }
    };
    const deleteImage = async () => {
        try {
            await _product.deleteProductImage(product.image._id,product._id);
            onChange("image",null);
            goBack();
            toast.success("Imagen eliminada");
        } catch (err) {
            console.error(err);
            toast.error("Error eliminando imagen.");
        }
    };
    const onChangeImage = (event) => {
        if (event.target.files && event.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setImgSrc(reader.result.toString());
            });
            reader.readAsDataURL(event.target.files[0]);
        }
        event.target.value = '';
    }
    const handleNewFile = () => {
        inputFileRef.current.click();
    }

    return (
        <Card id="new-product-images" className='col-xl-7 col-lg-8 col-md-9 col-10'>
            <input type="file" className='d-none' ref={inputFileRef} onChange={onChangeImage} />
            <ImageCropModal show={imgSrc} onHide={() => setImgSrc(null)} onCrop={saveImage} imgSrc={imgSrc} setImgSrc={setImgSrc} />
            <Card.Header className={classnames('', { disabled: disabled && isStep })} as="h5">Imagen del producto</Card.Header>
            <Card.Body className={classnames('d-flex justify-content-center', { disabled: disabled && isStep })}>
                <Col xs={6}>
                    {product.image ?
                        < figure >
                            <img src={product.image.fileLink} />
                        </figure>
                        :
                        <button className='image-cropper-trigger' onClick={handleNewFile}>
                            <TbCameraPlus size={50} color='rgba(63, 62, 62, 0.9)' />
                            <div>Nueva foto</div>
                        </button>
                    }
                </Col>
            </Card.Body>
            <Card.Footer className='d-flex justify-content-end'>
                            <ButtonSecondaryTranslucidHover disabled = {!product.image} onClick={deleteImage}>
                                Eliminar
                            </ButtonSecondaryTranslucidHover>
                {!disabled && isStep && <Button onClick={nextStep} disabled={!product.image}>
                    Siguiente
                </Button>}         
            </Card.Footer>
        </Card >
    );

}